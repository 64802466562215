import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import SanctionType from '../components/page-inner/sanction-type'
import Layout from '../components/layout'

export const query = graphql`
  query SanctionTypeTemplateQuery($id: String!) {
    sanctionType: sanitySanctionType(id: { eq: $id }) {
      id
      title
      description
      path
      typeSections {
        ... on SanityCasesSection {
          _key
          _type
          episodes {
            id
            episodeTitle
            episodeNumber
            startDate
            endDate
            path
            slug {
              current
            }
            inCase {
              endDate
              startDate
              title
              id
              slug {
                current
              }
            }
          }
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
        ... on SanityContentSection {
          _key
          _type
          _rawSectionContent(resolveReferences: { maxDepth: 5 })
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
        ... on SanityMultiContentSection {
          _key
          _type
          resolutionSections {
            _key
            title
            resolutionSections {
              _key
              _rawContent
              paragraph
              resolutionName
            }
          }
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
        ... on SanitySelectedResolutionsSection {
          _key
          _type
          resolutions {
            _key
            resolutionName
          }
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
      }
      category {
        id
        title
      }
      contextType {
        id
        title
      }
    }
  }
`

const SanctionTypeTemplate = props => {
  const { data, errors } = props
  const sanctionType = data && data.sanctionType

  // Format: Category/Context type
  const categoryTitle = sanctionType.category ? sanctionType.category.title : ''
  const contextTypeTitle = sanctionType.contextType ? sanctionType.contextType.title : ''
  const title = categoryTitle + ' / ' + contextTypeTitle
  const metaTitle = sanctionType.title ? sanctionType.title : ''

  return (
    <Layout title={title} {...props}>
      {errors && <SEO title="GraphQL Error" />}
      {sanctionType && <SEO title={metaTitle} />}

      {errors && <GraphQLErrorList errors={errors} />}

      {sanctionType && <SanctionType {...sanctionType} location={props.location} />}
    </Layout>
  )
}

export default SanctionTypeTemplate
